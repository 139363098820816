/**
*
* Placeholder for SubscribeSuccess
*
*/

// The actual component
const SubscribeSuccess = () => (null)

export default SubscribeSuccess;
